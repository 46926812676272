header {
    display: flex;
    flex-direction: row;
    height: 100px;
    align-items: center;
}

header > h1 {
    padding: 0;
    font-size: 36px;
    font-weight: 700;
    /* padding-left: 20px; */
}

header > .logout {
    height: auto;
    margin: 0 0 0 auto;
}